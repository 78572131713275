export const approvedStates = [
  'AZ',
  'CA',
  'CO',
  'FL',
  'ID',
  'KS',
  'LA',
  'MA',
  'MD',
  'MT',
  'NC',
  'NJ',
  'NM',
  'NV',
  'OH',
  'OR',
  'RI',
  'TN',
  'TX',
  'UT',
  'VA',
];
export const signorsCountOptions = [1, 2, 3, 4];
export const signorsCountOptionsElsi = [1, 2, 3, 4, 5, 6, 7, 8];
export const lendersOptions = ['-', 'PennyMac', 'VIP'];
export const eNoteOptions = ['eNote - Y', 'eNote - N'];
export const loanTypeOptions = ['Purchase', 'Refinance'];
export const signingFeeOptions = [125, 150, 175];
export const BuyerSellerEnum = {
  BUYER: 'Buyer',
  SELLER: 'Seller',
  BUYER_SELLER: 'Buyer/Seller',
  NA: 'N/A',
};
export const buyerSellerOptions = [
  BuyerSellerEnum.BUYER,
  BuyerSellerEnum.SELLER,
  BuyerSellerEnum.BUYER_SELLER,
  BuyerSellerEnum.NA,
];

export const ELSI_SUBJECT_MAX_LENGTH = 60;
export const ELSI_MSG_MAX_LENGTH = 400;
